<template>
  <base-section
    id="affiliates"
    class="grey lighten-4"
    space="36"
  >
    <v-container>
      <base-section-heading
        title="Become our Partner"
        subtitle="You want to join us on our journey?"
      />
      <v-row justify="center">
        <v-col
          v-for="card of cards"
          :key="card.title"
          cols="12"
          sm="4"
          md="4"
        >
          <base-info-card
            align="center"
            v-bind="card"
            color="accent"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="text-center"
          cols="12"
        >
          <base-btn
            :block="$vuetify.breakpoint.smAndDown"
            color="primary"
            href="mailto:contact@aptamimetics.com?subject=Become%20a%20Partner"
            large
            target="_blank"
          >
            Contact Us
          </base-btn>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionPartners',

    data: () => ({
      cards: [
        {
          icon: 'fal fa-hand-holding-usd',
          title: 'Investor',
          text: 'We are currently looking for interested and motivated early investors. Please contact us for an initial meeting to learn more details.',
        },
        // {
        //   icon: 'fal fa-user-tie',
        //   title: 'Network',
        //   text: 'You are a professional in business dWe are interested to learn more.',
        // },
        {
          icon: 'fal fa-hands-helping',
          title: 'Collaboration',
          text: 'We are open to collaborations with universities and industry in the field of biopharmaceuticals and aptamer development.',
        },
      ],
    }),
  }
</script>
